ion-button.button-default,
ion-button.button-solid,
ion-button.button-round {
  --border-radius: 27px !important;
}
sc-map .searchbar-wrapper .searchbar-container {
  border-radius: 50px !important;
}
sc-map .searchbar-wrapper .searchbar-container input {
  border-radius: 50px !important;
}
.in-toolbar.in-segment-color {
  color: white !important;
  &.ios.segment-button-checked {
    color: black !important;
  }
}

input.searchbar-input {
  border-radius: 50px !important;
  background-color: white !important;
}
* {
  font-family: Barlow, Arial, Lucida Grande, sans-serif;
}
ion-toolbar ion-button {
  --ionicon-stroke-width: 50px;
}
ion-modal {
  --border-radius: 0.4rem !important;
}
