/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './theme/brand_style.scss';

/* Mapbox styles */
@import '~mapbox-gl/dist/mapbox-gl.css';

@font-face {
  font-family: 'connectors';
  src: url('./assets/icon/connectors/connectors.eot');
  src: url('./assets/icon/connectors/connectors.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/icon/connectors/connectors.woff') format('woff'),
    url('./assets/icon/connectors/connectors.ttf') format('truetype'),
    url('./assets/icon/connectors/connectors.svg#connectors') format('svg');
  font-weight: normal;
  font-style: normal;
}

i[class*='con-'] {
  position: relative;

  display: inline-block;
  font-family: 'connectors';
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.con-cee-blue:before {
  content: '\0041';
}
.con-cee-red-3:before {
  content: '\0042';
}
.con-combo-eu:before {
  content: '\0043';
}
.con-tesla-super:before {
  content: '\0044';
}
.con-chademo:before {
  content: '\0045';
}
.con-type-2:before {
  content: '\0046';
}
.con-type-1:before {
  content: '\0047';
}
.con-schuko:before {
  content: '\0048';
}

.spinner-center {
  display: block;
  margin: auto;
}
.txt-gray {
  color: var(--ion-color-gray);
}
.disabled-sheet-button {
  color: var(--ion-color-gray) !important;
  cursor: default;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --ripple-color: transparent !important;
  &.activated {
    background: transparent !important;
  }
}
.disabled-sheet-button > * {
  pointer-events: none;
}
ion-alert button.alert-button.delete-button {
  color: var(--ion-color-danger);
}
ion-header {
  ion-title,
  .title {
    color: var(--ion-color-toolbar-contrast);
    --color: var(--ion-color-toolbar-contrast);
    .toolbar-title {
      color: inherit;
    }
  }
}
ion-toolbar {
  --background: var(--ion-color-toolbar);
}
.toolbar-background {
  background-color: var(--ion-color-toolbar);
}
.alert-radio-label.sc-ion-alert-md {
  white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
  white-space: pre-line !important;
}
[icon-only] ion-icon.location-icon {
  font-size: 2em;
}
.bar-button-default-ios,
.bar-button-default.bar-button-ios-default,
.bar-button-clear-ios-default {
  color: var(--ion-color-primary);
}
@media (min-width: 800px) {
  .scroll-content {
    overflow-y: auto;
  }
}

ion-modal.alert-modal {
  @media (min-height: 500px) {
    ion-backdrop {
      visibility: visible;
    }
  }
  --min-width: 250px;
  --width: auto;
  --min-height: auto;
  --height: auto;
  --max-height: 90%;

  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  align-items: center;
  justify-content: center;

  contain: strict;
  .modal-wrapper {
    &,
    .ion-page,
    .ion-page .content,
    .ion-page .content .scroll-content {
      contain: content;
      position: relative;
      top: auto;
      left: auto;
    }

    display: flex;
    overflow: hidden;

    flex-direction: column;

    opacity: 0;

    height: auto;
  }
}

ion-modal.alert-modal.ios {
  background: transparent;
  --background: transparent;
}
ion-modal.alert-modal.md {
  background: transparent;
  --background: transparent;
}

.loading-content {
  white-space: pre;
}
.notifications {
  position: absolute;
  z-index: 100;
  top: 0px;
  width: 17px;
  height: 17px;

  padding: 0;
  right: -8px;
  border-radius: 100%;
  font-size: 10px;
  font-weight: 500;
  padding-top: 3px;

  &.ios {
    padding-left: 0px;
  }

  &.list {
    top: -5px;
    &.ios {
      top: -3px;
      right: -5px;
      padding-right: 1px;
    }
  }
}
.pac-container {
  .pac-item {
    padding: 8px 8px;
  }
  &:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
    background-image: none !important;
    height: 0px;
  }
}

ion-button {
  &.ios {
    --border-radius: 4px;
  }
}

ion-label.ios > p,
ion-label.md > p {
  color: var(--ion-color-gray);
}
ion-item.error {
  --min-height: 22px;
  height: 22px;
}
ion-label.error {
  margin-top: 0;
  margin-bottom: 7px;
}
p.error {
  color: var(--ion-color-danger) !important;
}
ion-note.md {
  font-size: 16px;
}

ion-modal.above-tabs {
  bottom: calc(val(--ion-safe-area-bottom) + 51px);
}

ion-modal.auto-height {
  --height: fit-content;
}

ion-modal.padding-horizontal {
  padding-left: 16px;
  padding-right: 16px;
}

// Swiper

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(
    --ion-text-color-step-800,
    #cccccc
  );
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(
    var(--ion-text-color-rgb, 0, 0, 0),
    0.25
  );
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(
    var(--ion-text-color-rgb, 0, 0, 0),
    0.5
  );
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
