// https://ionicframework.com/docs/theming/color-generator

/** SmartCharge color variables **/
:root {
	--ion-color-primary: #2b4c3c;
	--ion-color-primary-rgb: 43,76,60;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #264335;
	--ion-color-primary-tint: #405e50;
  
    --ion-color-primary-dark: #2b4c3c;
	--ion-color-primary-dark-rgb: 43,76,60;
	--ion-color-primary-dark-contrast: #ffffff;
	--ion-color-primary-dark-contrast-rgb: 255,255,255;
	--ion-color-primary-dark-shade: #264335;
	--ion-color-primary-dark-tint: #405e50;

	--ion-color-secondary: #fdcc17;
	--ion-color-secondary-rgb: 253,204,23;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #dfb414;
	--ion-color-secondary-tint: #fdd12e;
  
    --ion-color-success: #2b4c3c;
	--ion-color-success-rgb: 43,76,60;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #264335;
	--ion-color-success-tint: #405e50;
/*
	--ion-color-success: #4e7944;
	--ion-color-success-rgb: 78,121,68;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #456a3c;
	--ion-color-success-tint: #608657;
*/
	--ion-color-warning: #fdcc17;
	--ion-color-warning-rgb: 253,204,23;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #dfb414;
	--ion-color-warning-tint: #fdd12e;

	--ion-color-danger: #ef4453;
	--ion-color-danger-rgb: 239,68,83;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d23c49;
	--ion-color-danger-tint: #f15764;
  
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,36,40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    --ion-color-gray: #989aa2;
    --ion-color-gray-rgb: 152,154,162;
    --ion-color-gray-contrast: #000000;
    --ion-color-gray-contrast-rgb: 0,0,0;
    --ion-color-gray-shade: #86888f;
    --ion-color-gray-tint: #a2a4ab;
  
    --ion-color-light: #f8f8f8;
    --ion-color-light-rgb: 248,248,248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #dadada;
    --ion-color-light-tint: #f9f9f9;

    --ion-color-toolbar: #2b4c3c;
	--ion-color-toolbar-rgb: 43,76,60;
	--ion-color-toolbar-contrast: #ffffff;
	--ion-color-toolbar-contrast-rgb: 255,255,255;
	--ion-color-toolbar-shade: #264335;
	--ion-color-toolbar-tint: #405e50;
    /*
    --ion-color-toolbar: #f8f8f8;
    --ion-color-toolbar-rgb: 248,248,248;
    --ion-color-toolbar-contrast: #2b4c3c;
    --ion-color-toolbar-contrast-rgb: 43,76,60;
    --ion-color-toolbar-shade: #dadada;
    --ion-color-toolbar-tint: #f9f9f9;*/
/*
    --ion-color-toolbar-button: #2b4c3c;
	--ion-color-toolbar-button-rgb: 43,76,60;
	--ion-color-toolbar-button-contrast: #ffffff;
	--ion-color-toolbar-button-contrast-rgb: 255,255,255;
	--ion-color-toolbar-button-shade: #264335;
	--ion-color-toolbar-button-tint: #405e50;*/



    --ion-color-toolbar-button: #ffffff;
    --ion-color-toolbar-button-rgb: 255,255,255;
    --ion-color-toolbar-button-contrast: #000000;
    --ion-color-toolbar-button-contrast-rgb: 0,0,0;
    --ion-color-toolbar-button-shade: #dadada;
    --ion-color-toolbar-button-tint: #f9f9f9;

    --ion-color-black: #000000;
    --ion-color-black-rgb: 0,0,0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255,255,255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #1a1a1a;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #dadada;
    --ion-color-white-tint: #f9f9f9;

    --ion-color-charging: #adc7a7;
	--ion-color-charging-rgb: 173,199,167;
	--ion-color-charging-contrast: #000000;
	--ion-color-charging-contrast-rgb: 0,0,0;
	--ion-color-charging-shade: #98af93;
	--ion-color-charging-tint: #b5cdb0;
}
.ion-color-primary{
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
}
.ion-color-primary-dark{
    --ion-color-base: var(--ion-color-primary-dark);
    --ion-color-base-rgb: var(--ion-color-primary-dark-rgb);
    --ion-color-contrast: var(--ion-color-primary-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-dark-shade);
    --ion-color-tint: var(--ion-color-primary-dark-tint);
}
.ion-color-gray{
    --ion-color-base: var(--ion-color-gray);
    --ion-color-base-rgb: var(--ion-color-gray-rgb);
    --ion-color-contrast: var(--ion-color-gray-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-shade);
    --ion-color-tint: var(--ion-color-gray-tint);
}
.ion-color-toolbar{
    --ion-color-base: var(--ion-color-toolbar);
    --ion-color-base-rgb: var(--ion-color-toolbar-rgb);
    --ion-color-contrast: var(--ion-color-toolbar-contrast);
    --ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb);
    --ion-color-shade: var(--ion-color-toolbar-shade);
    --ion-color-tint: var(--ion-color-toolbar-tint);
}
.ion-color-toolbar-button{
    --ion-color-base: var(--ion-color-toolbar-button);
    --ion-color-base-rgb: var(--ion-color-toolbar-button-rgb);
    --ion-color-contrast: var(--ion-color-toolbar-button-contrast);
    --ion-color-contrast-rgb: var(--ion-color-toolbar-button-contrast-rgb);
    --ion-color-shade: var(--ion-color-toolbar-button-shade);
    --ion-color-tint: var(--ion-color-toolbar-button-tint);
}
.ion-color-toolbar-contrast{
    --ion-color-base: var(--ion-color-toolbar-contrast);
}
.ion-color-black{
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-white{
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
}
.ion-color-success{
    --ion-color-base: var(--ion-color-success);
    --ion-color-base-rgb: var(--ion-color-success-rgb);
    --ion-color-contrast: var(--ion-color-success-contrast);
    --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
    --ion-color-shade: var(--ion-color-success-shade);
    --ion-color-tint: var(--ion-color-success-tint);
}

